<template>
  <PlayerConfigProvider
    v-if="workspaceStore.state.workspace"
    :main-color="workspaceStore.state.workspace.mainColor"
    :background="workspaceStore.state.workspace.theme"
    :font-family="workspaceStore.state.workspace.fontFamily"
    :locale="workspaceStore.state.workspace.language"
  >
    <RouterView style="height: 100vh; width: 100%" />
  </PlayerConfigProvider>
</template>

<script lang="ts" setup>
import { useUserStore } from '@/auth/userStore';
import PlayerConfigProvider from '@/widgets-lib/common/components/PlayerConfigProvider/PlayerConfigProvider.vue';
import { watch } from 'vue';
import { useWorkspaceStore } from './store/workspaceStore';

const userStore = useUserStore();
const workspaceStore = useWorkspaceStore();

workspaceStore.actions.fetch();
watch(() => userStore.user, workspaceStore.actions.fetch);
</script>

<style lang="scss">
@import '@/styles/index.scss';
@import '@/assets/base.css';
@import '@/styles/colors.scss';
@import '@/styles/font.scss';

body {
  margin: 0;
  padding: 0;
  background-color: $background-base;
  font-family: $main-font-family;
}
</style>
